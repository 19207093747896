// NormalLayout.tsx
import React, { useState } from 'react';
import AppHeader from '../components/Header/AppHeaderWrapper';
import Footer from '../components/Footer/Footer';
import { Divider } from '@mui/material';

interface NormalLayoutProps {
    children: React.ReactNode;
}

const NormalLayout: React.FC<NormalLayoutProps> = ({ children }) => {
    const [$duration] = useState(300);

    return (
        <>
            <AppHeader $duration={$duration} />
            {children}
            <Divider />
            <Footer />
        </>
    );
};

export default NormalLayout;
