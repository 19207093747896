// VerifyIdentity.tsx
import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import VerificationStatus from 'pages/CartPage/VerificationStatus';
import { useSocketContext } from 'sockets/SocketContext'; // Adjust the import path as needed
import { c } from 'vite/dist/node/types.d-aGj9QkWt';
import { useDispatch } from 'react-redux';
import { setUpdateIdentityRequireCheck } from 'redux/auth/authSlice';

interface VerifyIdentityProps {
  onComplete: () => void;
}

const VerifyIdentity: React.FC<VerifyIdentityProps> = ({ onComplete }) => {

  const { t } = useTranslation();
  const [verificationStatus, setverificationStatus] = useState<string>('processing_verification');
  const { socket } = useSocketContext();
  const [errorMessage, setErrorMessage] = useState('');
  const [hasCompleted, setHasCompleted] = useState(false);
  const dispatch = useDispatch();

  const [verificationSecret, setVerificationSecret] = useState<string>('');
  const [verificationError, setVerificationError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (socket) {
      // Define event handlers
      const handleOrderStatusUpdate = (data: { status: string; verificationSecret?: string; verificationError?: string }) => {
        setverificationStatus(data.status);
        setVerificationSecret(data.verificationSecret || '');
        setVerificationError(data.verificationError);
      };
      const handleOrderError = (error: { message: string }) => {
        console.error('Received orderError:', error);
        setErrorMessage(error.message);
        setverificationStatus('failed');
      };

      // Register event listeners
      socket.on('orderStatusUpdate', handleOrderStatusUpdate);
      socket.on('orderError', handleOrderError);
      // After handlers are registered, request the latest order status
      socket.emit('requestOrderStatus');
      // Register event listeners
      socket.on('noOrders', handleOrderStatusUpdate);
      socket.on('orderError', handleOrderError);
      // After handlers are registered, request the latest order status
      socket.emit('noOrders');

      // Cleanup event listeners on unmount
      return () => {
        socket.off('orderStatusUpdate', handleOrderStatusUpdate);
        socket.off('orderError', handleOrderError);
      };
    } else {
      console.log('Socket not available');
    }
  }, [socket]);

  useEffect(() => {
    if (verificationStatus === 'verification_success' && !hasCompleted) {
      setHasCompleted(true);
      dispatch(setUpdateIdentityRequireCheck(false));
      onComplete();
    }
  }, [verificationStatus, hasCompleted, dispatch, onComplete]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <VerificationStatus
        verificationSecret={verificationSecret}
        verificationError={verificationError}
        paymentStatus={verificationStatus}
        fallbackMessage={'We detected unusual activity on your account. Please verify your identity to continue.'}
      />
      {errorMessage && (
        <Box sx={{ mt: 2, color: 'error.main' }}>
          {errorMessage}
        </Box>
      )}
    </Box>
  );
};

export default VerifyIdentity;
