import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './redux/store'; // Assuming you have a RootState type for the entire state
import { fetchLocationData } from './redux/services/locationActions';
import { fetchCategories, fetchPlatforms, fetchRegions, fetchTypes, fetchSettings, fetchDevelopers, fetchPublishers, fetchGenres, fetchRatings } from './redux/actions/fetchDataActions';

import { SnackbarProvider } from 'notistack';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { createTheme, ThemeProvider as MuiThemeProvider, CssBaseline, Divider } from '@mui/material';
import { ThemeProvider, useThemeContext } from './theme/ThemeContext';
import ScrollToTopWrapper from './utils/ScrollToTopWrapper';

import RoutesGroup from './RoutesGroup';
import WelcomeLayout from './layouts/WelcomeLayout';
import NormalLayout from './layouts/NormalLayout';
import './utils/i18n';
import GdprDialog from './components/GdprDialog';
import getBSTheme from './theme/BigswissTheme';

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { actualMode } = useThemeContext();

  const locationStatus = useSelector((state: RootState) => state.application.locationStatus);
  const hasMissingInfo = useSelector((state: RootState) => state.auth.hasMissingInfo);
  const isEmailVerified = useSelector((state: RootState) => state.auth.isEmailVerified);
  const identityRequireCheck = useSelector((state: RootState) => state.auth.identityRequireCheck);
  const welcomePageLoading = useSelector((state: RootState) => state.auth.welcomePageLoading);
  
  const user = useSelector((state: RootState) => state.auth.user); // Check if user is logged in

  useEffect(() => {
    if (locationStatus === 'idle') {
      dispatch(fetchLocationData());
    }
    dispatch(fetchPlatforms());
    dispatch(fetchRegions());
    dispatch(fetchCategories());
    dispatch(fetchTypes());
    dispatch(fetchDevelopers());
    dispatch(fetchPublishers());
    dispatch(fetchGenres());
    dispatch(fetchRatings());
    if (locationStatus === 'succeeded') {
      dispatch(fetchSettings());
    }
  }, [dispatch, locationStatus]);

  const LPtheme = useMemo(() => createTheme(getBSTheme(actualMode)), [actualMode]);

  // Determine if we need to use the restricted layout (welcome page)
  const isRestricted = !isEmailVerified || hasMissingInfo || identityRequireCheck || welcomePageLoading;

  return (
    <MuiThemeProvider theme={LPtheme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <ScrollToTopWrapper>
          {!user ? (
            // User is not logged in, render the normal layout
            <NormalLayout>
              <RoutesGroup />
            </NormalLayout>
          ) : isRestricted ? (
            // User is logged in but it's their first login or they have missing info
            <WelcomeLayout>
              <RoutesGroup />
            </WelcomeLayout>
          ) : (
            // User is logged in and has no restrictions, render normal layout
            <NormalLayout>
              <RoutesGroup />
            </NormalLayout>
          )}
          <GdprDialog />
        </ScrollToTopWrapper>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

const AppWrapper: React.FC = () => (
  <ThemeProvider>
    <GoogleOAuthProvider clientId={process.env.VITE_APP_GOOGLE_CLIENT_ID || ''}>
      <App />
    </GoogleOAuthProvider>
  </ThemeProvider>
);

export default AppWrapper;
