// SocketContext.tsx
import React, { createContext, useContext, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { initializeSocket, connectSocket, disconnectSocket, getSocket } from './socket'; // Adjust the import path as needed
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { logout } from 'redux/auth/authThunks';

interface SocketContextProps {
  socket: Socket | null;
}

const SocketContext = createContext<SocketContextProps>({ socket: null });

interface SocketProviderProps {
  children: React.ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = React.useState<Socket | null>(null);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const redirectToLogin = () => {
        dispatch(logout())
        .catch((error: unknown) => {
            console.error('Logout failed:', error);
        });
    };

    if (isLoggedIn) {
      initializeSocket(redirectToLogin);
      connectSocket();
      const socketInstance = getSocket();
      setSocket(socketInstance);
    } else {
      disconnectSocket();
      setSocket(null);
    }

    return () => {
      disconnectSocket();
      setSocket(null);
    };
  }, [isLoggedIn, dispatch]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  return useContext(SocketContext);
};