import axiosDefault from 'axios'; 
import axiosGetToken from './axiosGetToken'; 
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { FavoriteItem, User } from './authTypes';

interface PasswordChangePayload {
  currentPassword: string;
  newPassword: string;
}

export const addFavorite = createAsyncThunk(
    'auth/addFavorite',
    async (favoriteData: FavoriteItem, { getState, rejectWithValue }) => {
      const { user } = (getState() as RootState).auth;
      if (!user || !user._id) {
        return rejectWithValue('User not authenticated or User ID missing');
      }
      try {
        const response = await axiosGetToken.post('/users/favorites/add', {
          userId: user._id,
          ...favoriteData
        });
        return response.data;
      } catch (error) {
        if (axiosDefault.isAxiosError(error)) {
          return rejectWithValue('Failed to add favorite: ' + (error.response?.data || 'Unknown error'));
        }
        return rejectWithValue('Failed to add favorite: An unexpected error occurred');
      }
    }
  );
  
  export const removeFavorite = createAsyncThunk(
    'auth/removeFavorite',
    async (platformGroupId: string, { getState, rejectWithValue }) => {
      const { user } = (getState() as RootState).auth;
      if (!user || !user._id) {
        return rejectWithValue('User not authenticated');
      }
      try {
        const response = await axiosGetToken.post('/users/favorites/remove', {
          userId: user._id,
          platformGroupId
        });
        return response.data;
      } catch (error) {
        if (axiosDefault.isAxiosError(error)) {
          return rejectWithValue('Failed to remove favorite: ' + (error.response?.data || 'Unknown error'));
        }
        return rejectWithValue('Failed to remove favorite: An unexpected error occurred');
      }
    }
  );

  export const updateUserSettings = createAsyncThunk(
    'auth/updateUserSettings',
    async (updatedFields: Partial<User>, { getState, dispatch, rejectWithValue }) => { // Use Partial<User> for the fields you want to update
      const { user } = (getState() as RootState).auth;
      
      if (!user || !user._id) {
        return rejectWithValue('User not authenticated');
      }
  
      try {
        const response = await axiosGetToken.patch<{ success: boolean; user: User }>('/users/settings', {
          userId: user._id,
          ...updatedFields // Only the fields that need to be updated
        });
        console.log(response.data);
        if (response.data.success) {
          return response.data.user;
        } else {
          return rejectWithValue('Failed to update user settings');
        }
      } catch (error: unknown) {
        if (axiosDefault.isAxiosError(error) && error.response) {
          const errorMessage = error.response.data.error || error.response.data.errors?.[0]?.msg || 'Failed to update settings due to server error';
          return rejectWithValue(errorMessage);
        }
        return rejectWithValue('An unexpected error occurred while updating settings');
      }
    }
  );
  
  export const updatePassword = createAsyncThunk(
    'auth/updatePassword',
    async ({ currentPassword, newPassword }: PasswordChangePayload, { rejectWithValue }) => {
      try {
        const response = await axiosGetToken.post('/users/update-password', { currentPassword, newPassword });
        return response.data;
      } catch (error: unknown) {
        if (axiosDefault.isAxiosError(error) && error.response) {
          const errorMessage = error.response.data.error || error.response.data.errors?.[0]?.msg || 'Password update failed due to server error';
          return rejectWithValue(errorMessage);
        }
        return rejectWithValue('An unexpected error occurred during password update');
      }
    }
  );
  