import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosGetToken from './axiosGetToken';
import axiosDefault from 'axios';
import { RootState } from '../store';
import { FavoriteItem, Invoice } from './authTypes';


export const fetchUserData = createAsyncThunk(
  'auth/fetchUserData',
  async (_, { getState, rejectWithValue }) => {
    const { user } = (getState() as RootState).auth;
    if (!user || !user._id) {
      return rejectWithValue('User not authenticated');
    }

    try {
      const response = await axiosGetToken.get<{ user: any }>(`/67196127dafafecc127c8328/${user._id}`);
      return response.data.user;
    } catch (error: unknown) {
      if (axiosDefault.isAxiosError(error) && error.response) {
        const errorMessage = error.response.data.error || error.response.data.errors?.[0]?.msg || 'Failed to fetch user data due to server error';
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('An unexpected error occurred while fetching user data');
    }
  }
);

export const fetchUserFavorites = createAsyncThunk(
  'auth/fetchUserFavorites',
  async (_, { getState, rejectWithValue }) => {
    const { user } = (getState() as RootState).auth;
    if (!user || !user._id) {
      return rejectWithValue('User not authenticated');
    }
    try {
      const response = await axiosGetToken.get<FavoriteItem[]>(`/users/favorites`);
      return response.data;
    } catch (error: unknown) {
      console.error('Error fetching favorites:', error);
      if (axiosDefault.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message || 'Failed to fetch favorites due to server error');
      }
      return rejectWithValue('An unexpected error occurred while fetching favorites');
    }
  }
);

export const fetchUserInvoices = createAsyncThunk(
  'auth/fetchUserInvoices',
  async (_, { getState, rejectWithValue }) => {
    const { user } = (getState() as RootState).auth;
    if (!user || !user._id) {
      return rejectWithValue('User not authenticated');
    }
    try {
      const response = await axiosGetToken.get<Invoice[]>(`/users/invoices`);
      return response.data;
    } catch (error: unknown) {
      console.error('Error fetching invoices:', error);
      if (axiosDefault.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message || 'Failed to fetch invoices due to server error');
      }
      return rejectWithValue('An unexpected error occurred while fetching invoices');
    }
  }
);