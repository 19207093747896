import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductScreenshots } from 'redux/product/productActions';
import { RootState, AppDispatch } from 'redux/store';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Grid2 as Grid, Box, Typography, Dialog, IconButton, AppBar, Toolbar, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ProductGalleryProps {
    productId: string;
    title: string;
    video?: string;
}

const getYoutubeVideoId = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|e\/u\/\w+\/|embed\/|v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
};

export const ProductGallery = ({ productId, video, title }: ProductGalleryProps) => {
    const { t } = useTranslation();

    const dispatch = useDispatch<AppDispatch>();
    const { productDetails, isLoading, error } = useSelector((state: RootState) => state.productDetail);
    const product = productDetails[productId] || {};

    const [smallImages, setSmallImages] = useState<string[]>([]);
    const [largeImages, setLargeImages] = useState<string[]>([]);

    useEffect(() => {
        const fetchImages = async () => {
            const action = await dispatch(fetchProductScreenshots({ productId }));
            if (fetchProductScreenshots.fulfilled.match(action)) {
                const images = action.payload.images;
                const smImages = images.filter((url: string) => url.endsWith('_sm.webp'));
                const lgImages = images.filter((url: string) => url.endsWith('_lg.webp'));
                setSmallImages(smImages);
                setLargeImages(lgImages);
            } else {
                console.error('Failed to fetch images');
            }
        };

        fetchImages();
    }, [dispatch, productId]);

    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(0);
    const youtubeVideoId = video ? getYoutubeVideoId(video) : null;

    const handleClickOpen = (index: number) => {
        setSelectedImage(index);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const settings = {
        initialSlide: selectedImage,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Grid container sx={{ mb: 5 }}>
            <Typography variant="h3" sx={{ display: 'flex', flexDirection: 'row', mb: 4 }}>
                {t('game')}&nbsp;
                <Typography component="span" variant="h3" sx={{ color: (theme) => theme.palette.mode === 'light' ? 'primary.main' : 'primary.light' }}>
                    {t('gallery')}&nbsp;
                </Typography>
            </Typography>

            {youtubeVideoId && (
                <Grid size={{ xs: 12 }}>
                    <Box sx={{ position: 'relative', paddingBottom: '56.25%', marginBottom: '34px', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000', borderRadius: '21px' }}>
                        <iframe
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0 }}
                            src={`https://www.youtube-nocookie.com/embed/${youtubeVideoId}`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </Box>
                </Grid>
            )}

            <Grid container spacing={4}>
                {smallImages.map((image, index) => (
                    <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
                        <Box
                            component="img"
                            sx={{ width: '100%', height: 'auto', borderRadius: '21px', cursor: 'pointer' }}
                            src={`https://bucket.bigswiss.ch/${image}`}
                            alt={`Game screenshot ${index + 1}`}
                            onClick={() => handleClickOpen(index)}
                        />
                    </Grid>
                ))}
            </Grid>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                PaperProps={{ sx: { px: 3 } }}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1, display: 'flex', alignItems: 'center' }} variant="h6" component="div">
                            <img src="img/avatar.svg" height={30} width={30} style={{ marginRight: '21px' }} /> {title} Gallery
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent sx={{ p: 4, overflow: 'hidden' }}>
                    <Slider {...settings} className="custom-slick-slider">
                        {largeImages.map((image, index) => (
                            <Box
                                key={index}
                                component="img"
                                sx={{
                                    maxHeight: '85vh',
                                    width: 'auto',
                                    height: 'auto',
                                    display: 'block',
                                    margin: '0 auto',
                                    objectFit: 'contain'
                                }}
                                src={`https://bucket.bigswiss.ch/${image}`}
                                alt={`Game screenshot ${index + 1}`}
                            />
                        ))}
                    </Slider>
                </DialogContent>
            </Dialog>
        </Grid>
    );
};
