import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Tooltip, Autocomplete, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import countries from 'utils/countries.json';
import { updateUserSettings } from 'redux/auth/updateUserThunks';

interface BillingInformationProps {
  onComplete: () => void;
}

const BillingInformation: React.FC<BillingInformationProps> = ({ onComplete }) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch<AppDispatch>();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState<{ name: string; code: string } | null>(countries.find(c => c.code === user?.country) || null);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName === 'Unknown' ? '' : user.lastName);
      setBirthday(user.birthday ? new Date(user.birthday).toISOString().substring(0, 10) : '');
      setAddress(user.address || '');
      setCity(user.city || '');
      setZip(user.zip || '');
      setCountry(countries.find(c => c.code === user.country) || null);
    }
  }, [user]);


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const updatedFields = {
      firstName,
      lastName,
      birthday,
      address,
      city,
      zip,
    };
  
    dispatch(updateUserSettings(updatedFields));
    onComplete();
  };
  

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
      }}
    >
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Typography variant='h4' textAlign={'center'} sx={{ fontWeight: 1000 }}>
          Billing Information
        </Typography>

        <Typography variant='body1' textAlign={'center'}>
          Please confirm your billing information.
        </Typography>

        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          label={t('yourName')}
          name="firstName"
          autoComplete="given-name"
          autoFocus
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          label={t('yourLastName')}
          name="lastName"
          autoComplete="family-name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          label={t('birthday')}
          name="birthday"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          label={t('address')}
          name="address"
          autoComplete="address-line1"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          label={t('city')}
          name="city"
          autoComplete="address-level2"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          label={t('zip')}
          name="zip"
          autoComplete="postal-code"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
        />
        <Tooltip
          arrow
          placement="top"
          title={
            <span>
              {t('contactSupportText')} <Link href="mailto:support@bigswiss.ch?subject=Region%20change">support@bigswiss.ch</Link>
            </span>
          }
        >
          <Autocomplete
            options={countries}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params}
              label={t('region')}
              size="small"
              margin="normal" required />}
            disabled
            value={country}
            onChange={(event, newValue) => setCountry(newValue)}
            fullWidth
          />
        </Tooltip>

        <Typography variant='body1' textAlign={'center'} sx={{ pt: 2 }}>
          Please double-check the details carefully, as changes cannot be made later without contacting support.
        </Typography>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          style={{ marginTop: '20px' }}
          type="submit"
        >
          Confirm Information
        </Button>
      </form>
    </Box>
  );
};

export default BillingInformation;
