import React, { useState, useRef } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmail } from 'redux/auth/authThunks';
import { RootState, AppDispatch } from 'redux/store';

interface VerifyEmailProps {
  onComplete: () => void;
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({ onComplete }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState(Array(6).fill('')); // State for 6-digit code

  // Select loading and error states from Redux
  const { isLoading } = useSelector((state: RootState) => state.auth);
  const user = useSelector((state: RootState) => state.auth.user);
  const [isDelayLoading, setIsDelayLoading] = useState(false);

  // Create refs for each input
  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

  // Handle input change for the 6-digit verification code
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);

      if (index < 5 && value) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  // Improve key down for backspace to immediately go back when empty
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      const newCode = [...verificationCode];
      if (verificationCode[index] !== '') {
        newCode[index] = '';
        setVerificationCode(newCode);
      } else if (index > 0) {
        // Move to the previous input if the current input is empty
        inputsRef.current[index - 1]?.focus();
      }
    }
  };

  // Handle pasting the full code, regardless of its length
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').trim();
    const pastedNumbers = pasteData.replace(/\D/g, ''); // Remove non-digit characters

    const newCode = [...verificationCode];
    for (let i = 0; i < pastedNumbers.length && i < 6; i++) {
      newCode[i] = pastedNumbers[i];
    }
    setVerificationCode(newCode);

    // Focus the next available input if any
    const nextInputIndex = pastedNumbers.length < 6 ? pastedNumbers.length : 5;
    inputsRef.current[nextInputIndex]?.focus();
  };

  // Handle focusing the first input if the user clicks on any input
  const handleFocusFirstInput = (index: number) => {
    if (index !== 0) {
      inputsRef.current[0]?.focus();
    }
  };

  const handleSubmit = async () => {
    const code = verificationCode.join('');
    if (code.length === 6) {
      try {
        // Dispatch verifyEmail thunk
        const resultAction = await dispatch(verifyEmail({ verificationCode: code }));
  
        if (verifyEmail.fulfilled.match(resultAction)) {
          // Handle successful verification with a delay
          const delay = 2000; // 2 seconds
          setIsDelayLoading(true);
          setTimeout(() => {
            setIsDelayLoading(false);
            onComplete(); 
          }, delay);
        } else {
          // Handle error
          console.error(resultAction.payload || 'Error verifying email');
        }
      } catch (error) {
        console.error('Error dispatching verifyEmail:', error);
      }
    } else {
      console.error('Invalid verification code:', code);
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h4" textAlign="center" sx={{ fontWeight: 1000 }}>
        {t('Verify e-mail')}
      </Typography>
      <Typography variant="body1" textAlign="center">
        {t('Please enter the code that was sent to')} {user?.email || 'e-mail.'}
      </Typography>

      {/* Inline Inputs for the 6-digit Code */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          marginTop: '20px',
        }}
      >
        {verificationCode.map((digit, index) => (
          <TextField
            key={index}
            id={`input-${index}`}
            slotProps={{
              htmlInput: {
                maxLength: 1,
                style: { textAlign: 'center' },
                inputMode: 'numeric',
                pattern: '[0-9]*',
              },
            }}
            value={digit}
            onClick={() => handleFocusFirstInput(index)} // Focus the first input when any input is clicked
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, index)} // Fix type error
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, index)} // Fix type error
            onPaste={index === 0 ? handlePaste : undefined} // Attach onPaste only to the first input
            size="small"
            variant="outlined"
            sx={{
              width: '40px',
              fontSize: '24px',
            }}
            inputRef={(el) => (inputsRef.current[index] = el)}
          />
        ))}
      </Box>

      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '20px' }}
        onClick={handleSubmit}
        disabled={isLoading || isDelayLoading}
      >
        {isDelayLoading ? t('Processing...') : t('Confirm code')}
      </Button>

    </Box>
  );
};

export default VerifyEmail;
