import React, { useRef, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { verifyGameActivation } from 'redux/payment/paymentThunks';
import { Box, Button, Typography, Stack } from '@mui/material';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import KeyIcon from '@mui/icons-material/Key';
import ProductKeys from './Components/ProductKeys';
import { useTranslation } from 'react-i18next';

import LoadingAnimation from 'components/Animations/LoadingAnimation';
import ManualVerificationModal from 'pages/CartPage/Components/ManualVerificationModal';
import InvoicePdfGenerator from 'components/Invoice/InvoicePdfGenerator';

const GameActivation: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const paymentIntent = queryParams.get('payment_intent');
    const paymentIntentClientSecret = queryParams.get('payment_intent_client_secret');

    const [verificationError, setVerificationError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [invoice, setInvoice] = useState<any>(null);
    const [retryCount, setRetryCount] = useState(0);

    const productKeysRef = useRef<HTMLDivElement>(null);
    const [verificationModalOpen, setVerificationModalOpen] = useState(false);
    const [modalTriggered, setModalTriggered] = useState(false);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const verifyPayment = async () => {
            if (paymentIntent && paymentIntentClientSecret) {
                try {
                    const response = await dispatch<any>(verifyGameActivation({
                        paymentIntent,
                        clientSecret: paymentIntentClientSecret,
                    }));

                    if (response.meta.requestStatus === 'rejected') {
                        // Check if the error is due to payment processing delay
                        if (response.payload === 'Payment is still processing.') {
                            // Retry verification after a delay
                            if (retryCount < 10) {
                                setRetryCount(prev => prev + 10);
                            } else {
                                setVerificationError(true);
                                setLoading(false);
                            }
                        } else {
                            setVerificationError(true);
                            setLoading(false);
                        }
                    } else {
                        setInvoice(response.payload);
                        setLoading(false);
                    }
                } catch (error) {
                    setVerificationError(true);
                    setLoading(false);
                }
            } else {
                setVerificationError(true);
                setLoading(false);
            }
        };

        verifyPayment();

        if (retryCount > 0 && retryCount < 5) {
            intervalId = setInterval(() => {
                verifyPayment();
            }, 6000); // Retry every 3 seconds
        }

        return () => clearInterval(intervalId);
    }, [dispatch, paymentIntent, paymentIntentClientSecret, retryCount]);

    useEffect(() => {
        if (invoice?.items && !modalTriggered) {
            const hasUndefinedKey = invoice.items.some((item: { keys: Array<{ key: string }> }) => item.keys.some(keyObj => keyObj.key === undefined));
            if (hasUndefinedKey && !verificationModalOpen) {
                setVerificationModalOpen(true);
                setModalTriggered(true);
            }
        }
    }, [invoice, verificationModalOpen, modalTriggered]);

    const handleVerificationModalClose = () => {
        setVerificationModalOpen(false);
    };

    const handleViewKeysClick = () => {
        if (productKeysRef.current) {
            const offsetTop = productKeysRef.current.getBoundingClientRect().top + window.pageYOffset - 100;
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    };

    if (loading || verificationError) {
        return <LoadingAnimation />;
    }


    return (
        <Box sx={{ display: 'flex', paddingLeft: '0 !important', paddingRight: '0 !important', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img src="/img/payment_success.webp" height={250} alt="Payment Success" />
            <Typography variant="h2" sx={{ mb: 3 }}>{t('thankYouForPurchase')}</Typography>
            <Typography variant="h6">{t('findYourKeysBellow')}</Typography>

            <Stack direction="row" spacing={4} sx={{ my: 5 }}>
                <Button sx={{ mt: 5 }} startIcon={<ArrowBackRoundedIcon />} variant="contained" color="secondary" component={RouterLink} to="/">
                    {t('goToStore')}
                </Button>
                {invoice && (
                    <InvoicePdfGenerator invoice={invoice} title={t('downloadInvoice')} />
                )}
                <Button sx={{ mt: 5 }} startIcon={<KeyIcon />} variant="contained" color="primary" onClick={handleViewKeysClick}>
                    {t('viewKeys')}
                </Button>
            </Stack>

            <Typography ref={productKeysRef} variant='h2' sx={{ my: 5 }}>
                {t('productKey')}
            </Typography>

            {invoice?.items.map((item: any, index: number) => (
                item.keys && item.keys.length > 0 ? (
                    item.keys.map((keyObj: { key: string; isRevealed: boolean; isGifted: string | null }, keyIndex: number) => (
                        <ProductKeys
                            invoiceId={invoice._id}
                            key={`${index}-${keyIndex}`}
                            productId={item.productId}
                            platformGroupId={item.platformGroupId}
                            keyCode={keyObj.key}
                            keyIsRevealed={keyObj.isRevealed}
                            isGifted={keyObj.isGifted}
                            productKeysRef={productKeysRef}
                        />
                    ))
                ) : (
                    Array.from({ length: item.quantity }).map((_, i) => (
                        <ProductKeys
                            invoiceId={invoice._id}
                            key={`${index}-${i}`}
                            productId={item.productId}
                            platformGroupId={item.platformGroupId}
                            keyCode={undefined}
                            productKeysRef={productKeysRef}
                        />
                    ))
                )
            ))}

            <ManualVerificationModal
                open={verificationModalOpen}
                onClose={handleVerificationModalClose}
            />
        </Box>
    );
};

export default GameActivation;
