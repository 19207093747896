import React from 'react';
import { Typography, Container, Button } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ManualReview: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <img src="/img/payment_block.webp" height={250} alt="Empty cart" />
            <Typography variant="h4" sx={{ mb: 3 }}>Payment review in progress</Typography>
            <Typography variant="h6" sx={{ mb: 3 }}>Please wait while our support review your previous purchase.</Typography>

            <Button startIcon={<ArrowBackRoundedIcon />} variant="contained" color="secondary" onClick={() => navigate('/products')}>
                {t('exploreCatalog')}
            </Button>
        </Container>
    );
};

export default ManualReview;
