import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { clearError } from 'redux/auth/authSlice'; // Make sure to define this action

interface CoverComponentProps {
  onAnimationComplete: () => void;
}

const CoverComponent: React.FC<CoverComponentProps> = ({ onAnimationComplete }) => {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(200);
  const [transitionCircles, setTransitionCircles] = useState(false);
  
  // States for error message typing effect
  const [errorText, setErrorText] = useState('');
  const [isDeletingError, setIsDeletingError] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { error, isLoading } = useSelector((state: RootState) => state.auth);

  const baseTexts = [
    t('welcomeToBigswiss'),
    t('completeProfileMessage'),
  ];

  // Function to handle base texts typing effect
  useEffect(() => {
    const handleTyping = () => {
      const currentIndex = loopNum;
      const fullText = baseTexts[currentIndex];
      const updatedText = isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1);

      setText(updatedText);

      if (!isDeleting && updatedText === fullText) {
        setTimeout(() => setIsDeleting(true), 1200);
        setTypingSpeed(50);
      } else if (isDeleting && updatedText === '') {
        if (loopNum < baseTexts.length - 1) {
          setIsDeleting(false);
          setTypingSpeed(55);
          setLoopNum(loopNum + 1);
        } else {
          setTransitionCircles(true);
          setTimeout(() => {
            onAnimationComplete(); 
          }, 2000); 
        }
      }
    };

    const typingTimer = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(typingTimer);
  }, [text, isDeleting, typingSpeed, loopNum, baseTexts, onAnimationComplete]);

  // Function to handle error message typing effect
  useEffect(() => {
    if (error) {
      const handleErrorTyping = () => {
        const fullErrorText = error;
        const updatedErrorText = isDeletingError
          ? fullErrorText.substring(0, errorText.length - 1)
          : fullErrorText.substring(0, errorText.length + 1);

        setErrorText(updatedErrorText);

        if (!isDeletingError && updatedErrorText === fullErrorText) {
          // Pause before deleting
          setTimeout(() => setIsDeletingError(true), 800);
          setTypingSpeed(10);
        } else if (isDeletingError && updatedErrorText === '') {
          // Error message fully deleted, reset states
          setIsDeletingError(false);
          setTypingSpeed(85);
          dispatch(clearError()); // Clear the error from Redux state
        }
      };

      const errorTypingTimer = setTimeout(handleErrorTyping, typingSpeed);
      return () => clearTimeout(errorTypingTimer);
    }
    
  }, [error, errorText, isDeletingError, typingSpeed, dispatch]);

  return (
    <Box sx={{
      display: 'flex', alignItems: 'center',
      justifyContent: transitionCircles ? 'flex-end' : 'center',
      transition: 'width 2s ease',
      position: 'relative',
      height: '100%'
    }}>
      <div
        className="circles"
        style={{
          width: transitionCircles ? '50%' : '100%',
          transition: 'width 2s ease',
        }}
      >
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
      <Box sx={{ position: 'absolute', zIndex: 10, textAlign: 'center' }}>
        <Typography
          variant="h2"
          className="typing-effect"
          sx={{ transition: 'opacity 0.6s', fontWeight: 900 }}
        >
          {text}
        </Typography>
        {error && (
          <Typography
            variant="h4"
            className="typing-effect"
    
            sx={{ transition: 'opacity 0.6s', fontWeight: 900,  maxWidth: '300px', marginRight: '150px' }}
          >
            {errorText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CoverComponent;
