const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  window.location.hostname === '[::1]' ||
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

interface Config {
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
}

export function register(config?: Config): Promise<ServiceWorkerRegistration> {
  return new Promise((resolve, reject) => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        const swUrl = `/service-worker.js`;

        if (isLocalhost) {
          // Check if the service worker can be found and valid for localhost
          checkValidServiceWorker(swUrl, config).then(resolve).catch(reject);
        } else {
          // For non-localhost, just register the service worker
          registerValidSW(swUrl, config).then(resolve).catch(reject);
        }
      });
    } else {
      reject(new Error('Service workers are not supported in this browser.'));
    }
  });
}

function registerValidSW(swUrl: string, config?: Config): Promise<ServiceWorkerRegistration> {
  return navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };

      return registration;
    })
    .catch((error) => {
      console.error('Service worker registration failed:', error);
      throw error;
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config): Promise<ServiceWorkerRegistration> {
  return fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then(response => {
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        return navigator.serviceWorker.ready.then(registration => {
          return registration.unregister().then(() => {
            window.location.reload();
            throw new Error('Service worker not found. Page reloaded.');
          });
        });
      } else {
        return registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      throw new Error('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}
