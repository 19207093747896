export interface InvoiceItem {
    productId: string;
    platformGroupId: string;
    price: number;
    key: string;
    quantity: number;
}

export interface Invoice {
    _id: string;
    userId: string;
    userEmail: string;
    userName: string;
    userAddress: string;
    userCity: string;
    userZip: string;
    userCountry: string;
    items: InvoiceItem[];
    total: number;
    paymentMethod: string;
    paymentId: string;
    currency: string;
    invoiceNumber: string;
    status: 'preorder' | 'paid';
    createdAt: string;
    version: number;
}

export interface FavoriteItem {
    productId: string;
    platformGroupId: string;
  }
  
  export interface User {
    email: string;
    _id: string;
    firstName: string;
    lastName: string;
    birthday: string;
    address: string;
    city: string;
    zip: string;
    country: string;
    favorites: FavoriteItem[];
    invoices: Invoice[];
  }
  
  export interface AuthState {
    user: User | null;
    token: string | null;
    isLoggedIn: boolean;
    welcomePageLoading: boolean;
    isLoading: boolean;
    hasMissingInfo: boolean;
    isEmailVerified: boolean;
    identityRequireCheck: boolean;
    isGoogleLoading: boolean;
    error: string | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
  }
  
  export const initialState: AuthState = {
    user: null,
    token: null,
    isLoggedIn: false,
    welcomePageLoading: false,
    isGoogleLoading: false,
    isLoading: false,
    isEmailVerified: false,
    hasMissingInfo: true,
    identityRequireCheck: false,
    error: null,
    status: 'idle'
  };
  