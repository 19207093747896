// socket.tsx
import { io, Socket } from 'socket.io-client';

let socket: Socket | null = null;

type RedirectCallback = () => void;

export const initializeSocket = (redirectToLogin: RedirectCallback) => {
  if (!socket) {
    socket = io('/', {
      withCredentials: true,
      path: '/socket.io',
      transports: ['websocket', 'polling'],
      autoConnect: false,
    });

    socket.on('connect_error', (err) => {
      console.error('Socket connection error:', err.message);
    });

    socket.on('authError', (data) => {
      console.warn(data.message);
      // Use the passed-in callback to redirect
      redirectToLogin();
    });

  }
  return socket;
};

export const connectSocket = () => {
  if (socket && !socket.connected) {
    socket.connect();
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

export const getSocket = () => socket;

// Default export
const socketManager = {
  initializeSocket,
  connectSocket,
  disconnectSocket,
  getSocket,
};

export default socketManager;
