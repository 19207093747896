import React, { useState } from 'react';
import { ListItemIcon, Divider, Menu, MenuItem, Avatar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { logout } from 'redux/auth/authThunks';
import useMenuItems from './menuItems';
import { getUserAvatarUrl } from 'utils/getNames';
import { useNavigate } from 'react-router-dom';

interface UserMenuProps {
  anchorEl?: null | HTMLElement;
  handleClose: () => void;
}

const avatars = [
  '/img/avatar-1.webp',
  '/img/avatar-2.webp',
];

const getRandomAvatar = () => {
  const randomIndex = Math.floor(Math.random() * avatars.length);
  return avatars[randomIndex];
};

const UserMenuItems: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const menuItems = useMenuItems();

  const [avatarUrl, setAvatarUrl] = useState(getRandomAvatar());

  const handleImageError = () => {
    // Fallback to a random avatar if the image fails to load
    setAvatarUrl(getRandomAvatar());
  };

  const handleLogout = () => {
    // Navigate to '/login' immediately
    navigate('/login');
    // Then dispatch the logout action
    dispatch(logout())
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch((error: unknown) => {
        console.error('Logout failed:', error);
      });
  };

  return (
    <>
      <MenuItem component={RouterLink} to="/dashboard" onClick={handleClose}>
        <ListItemIcon>
          <Avatar
            sx={{ mr: 2, color: 'text.primary' }}
            src={avatarUrl}
            onError={handleImageError}
          />
        </ListItemIcon>
        {user?.firstName} {user?.lastName}
      </MenuItem>
      {menuItems.map((item, index) => (
        <MenuItem key={index} component={RouterLink} to={item.path} onClick={handleClose}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          {item.label}
        </MenuItem>
      ))}
      <Divider />
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </>
  );
};

const UserMenu: React.FC<UserMenuProps> = ({ anchorEl, handleClose }) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl!}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
    >
      <UserMenuItems handleClose={handleClose} />
    </Menu>
  );
};

export { UserMenuItems };
export default UserMenu;
