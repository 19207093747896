import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Typography, Card, Autocomplete, Stack, Tooltip, Link } from '@mui/material';
import { fetchUserData } from 'redux/auth/fetchUserThunks';
import { updateUserSettings } from 'redux/auth/updateUserThunks';
import { RootState, AppDispatch } from 'redux/store';
import countries from 'utils/countries.json';
import ToggleColorMode from 'theme/ToggleColorMode';
import LanguageSwitcher from 'components/LanguageSwitcher';
import useSnackbarAlert from 'utils/useSnackbarAlert';
import NotificationSettings from './Components/NotificationSettings';

const UserSettingsPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.auth.user);
    const { isLoading, error } = useSelector((state: RootState) => state.auth);

    const [firstName, setFirstName] = useState(user?.firstName || '');
    const [lastName, setLastName] = useState(user?.lastName || '');
    const [birthday, setBirthday] = useState(user?.birthday ? new Date(user.birthday).toISOString().substring(0, 10) : '');
    const [country, setCountry] = useState<{ name: string; code: string } | null>(countries.find(c => c.code === user?.country) || null);
    const [email, setEmail] = useState(user?.email || '');

    const [address, setAddress] = useState(user?.address || '');
    const [city, setCity] = useState(user?.city || '');
    const [zip, setZip] = useState(user?.zip || '');

    const showAlert = useSnackbarAlert();

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setBirthday(user.birthday ? new Date(user.birthday).toISOString().substring(0, 10) : '');
            setAddress(user.address || '');
            setCity(user.city || '');
            setZip(user.zip || '');
            setCountry(countries.find(c => c.code === user.country) || null);
            setEmail(user.email);


        }
    }, [user]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const trimmedEmail = email.trim();

        dispatch(updateUserSettings({
            firstName,
            lastName,
            birthday,
            address,
            city,
            zip,
            country: country?.code || '',
            email: trimmedEmail,
          
        })).then((action: any) => {
            if (updateUserSettings.fulfilled.match(action)) {
                showAlert('User settings updated successfully. Please relogin.', 'success');
                dispatch(fetchUserData());
            } else {
                showAlert(action.payload || 'Failed to update user settings', 'error');
            }
        });
    };


    return (
        <Stack spacing={3}>
            <Card elevation={6} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5">{t('updatePersonalData')}</Typography>
                <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 8 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        size="small"
                        label={t('yourName')}
                        name="firstName"
                        autoComplete="given-name"
                        autoFocus
                        disabled
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)} 
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        size="small"
                        label={t('yourLastName')}
                        name="lastName"
                        autoComplete="family-name"
                        value={lastName}
                        disabled
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        size="small"
                        label={t('birthday')}
                        name="birthday"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={birthday}
                        onChange={(e) => setBirthday(e.target.value)} 
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        size="small"
                        label={t('address')}  
                        name="address"
                        autoComplete="address-line1"
                        value={address}
                        disabled
                        onChange={(e) => setAddress(e.target.value)} 
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        size="small"
                        label={t('city')}  
                        name="city"
                        autoComplete="address-level2"
                        value={city}
                        disabled
                        onChange={(e) => setCity(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        size="small"
                        label={t('zip')} 
                        name="zip"
                        autoComplete="postal-code"
                        value={zip}
                        disabled
                        onChange={(e) => setZip(e.target.value)}
                    />
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={
                            <span>
                                {t('contactSupportText')} <Link href="mailto:support@bigswiss.ch?subject=Region%20change">support@bigswiss.ch</Link>
                            </span>
                        }
                    >
                        <Autocomplete
                            options={countries}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params}
                                label={t('region')}
                                size="small"
                                margin="normal" required />}
                            disabled
                            value={country}
                            onChange={(event, newValue) => setCountry(newValue)}
                            fullWidth
                        />
                    </Tooltip>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        size="small"
                        label={t('yourEmailAddress')}
                        name="email"
                        autoComplete="email"
                        value={email}
                        disabled
                    />
                    {error && <Typography color="error">{error}</Typography>}
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isLoading}>
                        {t('updateSettings')}
                    </Button>
                </form>
            </Card>

            <Card elevation={6} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }} component={Stack} spacing={3}>
                <Typography component="h1" variant="h5" mb={0}>{t('updateApplicationSettings')}</Typography>
                <NotificationSettings />
                <Typography variant="body1" textAlign={'left'} sx={{ width: '100%', mb: 0 }}>{t('changeApplicationTheme')}</Typography>
                <ToggleColorMode fullWidth />
                <Typography variant="body1" textAlign={'left'} sx={{ width: '100%', mb: 0 }}> {t('selectLanguage')}</Typography>
                <LanguageSwitcher fullWidth />
            </Card>
        </Stack>
    );
};

export default UserSettingsPage;
